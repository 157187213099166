.App{
  width: 100%;
  height: 100vh;
  direction: rtl;
}

.navbar{
  z-index: 1;
}
.content {
  position: absolute;
  width: 100%;
  height:100%;
  top:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size:large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, .1);
  top:0;
  left:0
}

.customers{
  /* width: 90%;
  height: 90%; */
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: rgba(0, 0, 0, 0.1); */
  
  width: 100%;
  height:100%;
  top:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size:large;
}